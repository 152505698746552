<template>
  <v-card flat class="mb-3">
    <v-card-text class="pt-2">
      <v-card-title class="pt-0">
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="ค้นหา" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="desserts" :search="search"></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'เลขผู้เสียภาษี',
          align: 'start',
          sortable: false,
          value: 'numberTax',
        },
        { text: 'ชื่อบริษัท', value: 'nameCompany' },
        { text: 'สาขา', value: 'branch' },
        { text: 'ที่อยู่', value: 'address' },
        { text: 'ตัวเลือก', value: 'choice' },
      ],
      desserts: [
        {
          numberTax: 'th23938943',
          nameCompany: 'Frozen Yogurt',
          branch: 159,
          address: 'เชียงใหม่',
          choice: '-',
        },
        {
          numberTax: 'th568787877',
          nameCompany: 'Ice cream sandwich',
          branch: 159,
          address: 'เชียงใหม่',
          choice: '-',
        },
        {
          numberTax: 'th57431434',
          nameCompany: 'Eclair',
          branch: 159,
          address: 'เชียงใหม่',
          choice: '-',
        },
      ],
    }
  },
}
</script>

<style></style>
