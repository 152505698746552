<template>
  <v-card>
    <v-card-text>
      <!-- Description -->
      <!-- <h1>ใบสั่งซื้อ</h1> -->
      <!--      <div class="d-flex justify-space-between test2">-->
      <!--        <div class="test">-->
      <!--          &lt;!&ndash; ถึงกำหนดรับสินค้า &ndash;&gt;-->
      <!--          <div>-->
      <!--            <div>-->
      <!--              <p class="mb-0 text-center font-weight-bold">ถึงกำหนดรับสินค้า</p>-->
      <!--              <div class="me-2 box_content box-1">-->
      <!--                <div class="d-flex justify-center">-->
      <!--                  <p class="mb-0 me-1">-->
      <!--                    {{-->
      <!--                      this.$store.state.draft.amount +-->
      <!--                      this.$store.state.notpaid.amount +-->
      <!--                      this.$store.state.paid.amount +-->
      <!--                      this.$store.state.makeSend.amount +-->
      <!--                      this.$store.state.send.amount +-->
      <!--                      this.$store.state.expire.amount +-->
      <!--                      this.$store.state.cancel.amount-->
      <!--                    }}-->
      <!--                  </p>-->
      <!--                  <p class="mb-0">รายการ</p>-->
      <!--                </div>-->
      <!--                <p class="mb-0">฿ 100.00</p>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          &lt;!&ndash; รับสินค้าภายใน 7 วัน &ndash;&gt;-->
      <!--          <div>-->
      <!--            <p class="mb-0 text-center font-weight-bold">รับสินค้าภายใน 7 วัน</p>-->
      <!--            <div class="me-2 box_content box-2">-->
      <!--              <div class="d-flex justify-center">-->
      <!--                <p class="mb-0 me-1">{{ this.$store.state.draft.amount }}</p>-->
      <!--                <p class="mb-0">รายการ</p>-->
      <!--              </div>-->
      <!--              <p class="mb-0">฿ 0</p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          &lt;!&ndash; รับสินค้ามากกว่า 7 วัน &ndash;&gt;-->
      <!--          <div>-->
      <!--            <p class="mb-0 text-center font-weight-bold">รับสินค้ามากกว่า 7 วัน</p>-->
      <!--            <div class="me-2 box_content box-3">-->
      <!--              <div class="d-flex justify-center">-->
      <!--                <p class="mb-0 me-1">{{ this.$store.state.notpaid.amount }}</p>-->
      <!--                <p class="mb-0">รายการ</p>-->
      <!--              </div>-->
      <!--              <p class="mb-0">฿ 0</p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <div class="d-flex text-right">
        <v-dialog
          v-model="dialog"
          transition="dialog-top-transition"
          width="850"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-row>
              <v-col
                md="10"
                cols="9"
              ></v-col>
              <v-col
                md="2"
                cols="3"
              >
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  class="me-2 pull-right"
                  style="text-align: right;float: right"
                  @click="close()"
                  v-on="on"
                >
                  <v-icon class="me-2">
                    {{ icons.mdiPlusCircle }}
                  </v-icon>
                  สร้างใบสั่งซื้อสินค้า (PO)
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar
                color="primary"
                @click="close()"
              >
                <p class="mb-0">
                  {{ editedIndex > -1 ? 'แก้ไขใบเบิกสินค้า' : 'สร้างใบเบิกสินค้า' }}
                </p>
              </v-toolbar>
              <v-card flat>
                <v-card-text>
                  <!-- ประเภทใบสั่งซื้อ -->
                  <v-row>
                    <v-col
                      md="6"
                      sm="6"
                      cols="12"
                    >
                      <p class="mb-0">
                        ใบสั่งซื้อ: {{
                          editedItem ? editedItem.purchase_on : '-'
                        }}
                      </p>
                    </v-col>
                    <v-col
                      md="6"
                      sm="6"
                      cols="12"
                    >
                      <DatePicker
                        v-model="editedItem.date"
                        :disabled="editedItem.id != null && !editedItem.id !== ''"
                        value-type="format"
                        format="YYYY-MM-DD"
                        placeholder="วันที่"
                        label="วันที่"
                        style="width: 100%"
                      ></DatePicker>
                    </v-col>
                  </v-row>
                  <!--      <hr class="mb-3" />-->
                  <!--      <h3>ประเภทใบสั่งซื้อ</h3>-->
                  <!--      <p>กรุณาเลือกประเภทของการสั่งซื้อ เพื่อการลงบัญชีที่ถูกต้อง <a href="#">เรียนรู้เพิ่มเติม</a></p>-->
                  <!--      <v-radio-group v-model="radioGroup">-->
                  <!--        <v-radio v-for="n in dataRadio" :key="n" :label="`${n}`" :value="n"></v-radio>-->
                  <!--      </v-radio-group>-->
                  <!-- จบประเภทใบสั่งซื้อ -->

                  <hr class="mb-3 mt-3" />

                  <!-- ซัพพลายเออร์ -->
                  <v-row>
                    <v-col
                      md="6"
                      sm="6"
                      cols="12"
                    >
                      <h3>ซัพพลายเออร์</h3>
                      <!--          <p class="mb-0">เพิ่มรายละเอียดของซัพพลายเออร์ <a href="#">เรียนรู้เพิ่มเติม</a></p>-->
                    </v-col>
                    <v-col
                      md="6"
                      sm="6"
                      cols="12"
                      class="d-flex justify-end"
                    >
                      <!--          <v-dialog transition="dialog-top-transition" width="700">-->
                      <!--            <template v-slot:activator="{ on, attrs }">-->
                      <!--              <v-btn color="primary" text v-bind="attrs" v-on="on" class="me-2">-->
                      <!--                <v-icon>{{ icons.mdiMagnify }}</v-icon>-->
                      <!--                ข้อมูลกรมสรรพากร-->
                      <!--              </v-btn>-->
                      <!--            </template>-->
                      <!--            <template v-slot:default="dialog">-->
                      <!--              <v-card>-->
                      <!--                <v-toolbar color="primary">-->
                      <!--                  <p class="mb-0">ค้นหาข้อมุลบริษัทจากฐานข้อมูลกรมสรรพากร</p>-->
                      <!--                </v-toolbar>-->
                      <!--                <RevenueDepartment />-->
                      <!--                <v-card-actions class="justify-end">-->
                      <!--                  <v-btn text @click="dialog.value = false">Close</v-btn>-->
                      <!--                </v-card-actions>-->
                      <!--              </v-card>-->
                      <!--            </template>-->
                      <!--          </v-dialog>-->
                    </v-col>
                    <v-col
                      md="4"
                      sm="6"
                      cols="12"
                      class="d-flex align-center justify-end pt-1 pb-0"
                    >
                      <h4 class="text-end">
                        ค้นหาที่อยู่
                      </h4>
                    </v-col>
                    <v-col
                      md="8"
                      sm="6"
                      cols="12"
                      class="pt-1 pb-0"
                    >
                      <v-select
                        v-model="selected_address"
                        outlined
                        :items="address"
                        dense
                        hide-details
                        label="ค้นหาที่อยู่"
                        item-text="address"
                        item-value="address"
                        placeholder="ค้นหาที่อยู่"
                        class="mt-2 mb-3"
                        @input="address_select"
                      ></v-select>
                      <small
                        v-for="(err,index) in v$.editedItem.tax_id.$silentErrors"
                        v-if="v$.editedItem.tax_id.$error"
                        class="validate-err"
                      >{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>
                    </v-col>
                    <!-- ชื่อซัพพลายเออร์ -->
                    <v-col
                      md="4"
                      sm="6"
                      cols="12"
                      class="d-flex align-center justify-end pt-0 pb-0"
                    >
                      <h4 class="text-end">
                        ชื่อซัพพลายเออร์
                      </h4>
                    </v-col>
                    <v-col
                      md="8"
                      sm="6"
                      cols="12"
                      class="pt-0 pb-0"
                    >
                      <v-text-field
                        v-model="editedItem.supplier_name"
                        outlined
                        dense
                        hide-details
                        label="กรอกชื่อซัพพลายเออร์"
                        placeholder="เพิ่มชื่อซัพพลายเออร์ในนามบุคคล หรือ นิติบุคคล"
                        class="mt-2 mb-3"
                      ></v-text-field>

                      <small
                        v-for="(err,index) in v$.editedItem.supplier_name.$silentErrors"
                        v-if="v$.editedItem.supplier_name.$error"
                        class="validate-err"
                      >{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>
                    </v-col>
                    <!-- เลขประจำตัวผู้เสียภาษี -->
                    <v-col
                      md="4"
                      sm="6"
                      cols="12"
                      class="d-flex align-center justify-end pt-1 pb-0"
                    >
                      <h4 class="text-end">
                        เลขประจำตัวผู้เสียภาษี
                      </h4>
                    </v-col>
                    <v-col
                      md="8"
                      sm="6"
                      cols="12"
                      class="pt-1 pb-0"
                    >
                      <v-text-field
                        v-model="editedItem.tax_id"
                        outlined
                        dense
                        hide-details
                        label="กรอกเลขประจำตัวผู้เสียภาษี"
                        placeholder="เลขประจำตัวผู้เสียภาษี"
                        class="mt-2 mb-3"
                      ></v-text-field>
                      <small
                        v-for="(err,index) in v$.editedItem.tax_id.$silentErrors"
                        v-if="v$.editedItem.tax_id.$error"
                        class="validate-err"
                      >{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>
                    </v-col>
                    <!-- ที่อยู่ -->
                    <v-col
                      md="4"
                      sm="6"
                      cols="12"
                      class="d-flex align-center justify-end pt-0 pb-0"
                    >
                      <h4 class="text-end">
                        ที่อยู่
                      </h4>
                    </v-col>
                    <v-col
                      md="8"
                      sm="6"
                      cols="12"
                      class="pt-0 pb-0"
                    >
                      <v-textarea
                        v-model="editedItem.address"
                        class="mb-5"
                        outlined
                        hide-details
                        label="เพิ่มที่อยู่"
                      ></v-textarea>
                      <small
                        v-for="(err,index) in v$.editedItem.address.$silentErrors"
                        v-if="v$.editedItem.address.$error"
                        class="validate-err"
                      >{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>
                    </v-col>
                    <!-- ที่อยู่ -->
                    <!--                    <v-col md="4" sm="6" cols="12"-->
                    <!--                           class="d-flex align-center justify-end pt-0 pb-0">-->
                    <!--                      <h4 class="text-end">ชื่อโปรเจ็ค</h4>-->
                    <!--                    </v-col>-->
                    <!--                    <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">-->
                    <!--                      <v-text-field-->
                    <!--                        v-model="editedItem.project_name"-->
                    <!--                        outlined-->
                    <!--                        dense-->
                    <!--                        hide-details-->
                    <!--                        label="ชื่อโปรเจ็ค"-->
                    <!--                        placeholder="ชื่อโปรเจ็ค"-->
                    <!--                        class="mt-2 mb-3"-->
                    <!--                      ></v-text-field>-->
                    <!--                      &lt;!&ndash;          <v-radio-group v-model="radioGroup2">&ndash;&gt;-->
                    <!--                      &lt;!&ndash;            <v-radio v-for="n in buyForm" :key="n" :label="`${n}`" :value="n"></v-radio>&ndash;&gt;-->
                    <!--                      &lt;!&ndash;          </v-radio-group>&ndash;&gt;-->
                    <!--                    </v-col>-->
                    <!--                    &lt;!&ndash; ชื่อซัพพลายเออร์ &ndash;&gt;-->
                    <!--                    <v-col md="4" sm="6" cols="12"-->
                    <!--                           class="d-flex align-center justify-end pt-0 pb-0">-->
                    <!--                      <h4 class="text-end">ชื่อผู้ติดต่อ</h4>-->
                    <!--                    </v-col>-->
                    <!--                    <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">-->
                    <!--                      <v-text-field-->
                    <!--                        v-model="editedItem.contact_name"-->
                    <!--                        outlined-->
                    <!--                        dense-->
                    <!--                        hide-details-->
                    <!--                        label="ชื่อผู้ติดต่อ"-->
                    <!--                        placeholder="ชื่อผู้ติดต่อ"-->
                    <!--                        class="mt-2 mb-3"-->
                    <!--                      ></v-text-field>-->
                    <!--                    </v-col>-->
                    <v-col
                      md="4"
                      sm="6"
                      cols="12"
                      class="d-flex align-center justify-end pt-0 pb-0"
                    >
                      <h4 class="text-end">
                        ประเภทการชำระเงิน
                      </h4>
                    </v-col>
                    <v-col
                      md="8"
                      sm="6"
                      cols="12"
                      class="pt-0 pb-0"
                    >
                      <v-select
                        v-model="editedItem.payment_id"
                        :items="payments"
                        item-value="id"
                        item-text="name"
                      ></v-select>
                      <small
                        v-for="(err,index) in v$.editedItem.payment_id.$silentErrors"
                        v-if="v$.editedItem.payment_id.$error"
                        class="validate-err"
                      >{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>
                    </v-col>

                    <!-- ข้อมูลผู้ติดต่อ -->
                    <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">-->
                    <!--          <h4 class="text-end">ข้อมูลผู้ติดต่อ</h4>-->
                    <!--        </v-col>-->
                    <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">-->
                    <!--          <p v-if="!showData" @click="clickShowData" class="mb-0 pTagDynamic">แสดงข้อมูล</p>-->
                    <!--          <p v-if="showData" @click="clickShowData" class="mb-0 pTagDynamic">ซ่อนข้อมูล</p>-->
                    <!--          <div v-if="showData">-->
                    <!--            <v-text-field-->
                    <!--              v-model="contactPerson"-->
                    <!--              outlined-->
                    <!--              dense-->
                    <!--              hide-details-->
                    <!--              label="ผู้ติดต่อ"-->
                    <!--              placeholder="กรอกผู้ติดต่อ"-->
                    <!--              class="mt-2 mb-3"-->
                    <!--            ></v-text-field>-->
                    <!--            <v-text-field-->
                    <!--              v-model="emailPerson"-->
                    <!--              outlined-->
                    <!--              dense-->
                    <!--              hide-details-->
                    <!--              label="E-mail"-->
                    <!--              placeholder="กรอก Email"-->
                    <!--              class="mt-2 mb-3"-->
                    <!--            ></v-text-field>-->
                    <!--            <v-text-field-->
                    <!--              v-model="telPerson"-->
                    <!--              outlined-->
                    <!--              dense-->
                    <!--              hide-details-->
                    <!--              label="โทรศัพท์"-->
                    <!--              placeholder="กรอกเบอร์โทรศัพท์"-->
                    <!--              class="mt-2 mb-3"-->
                    <!--            ></v-text-field>-->
                    <!--          </div>-->
                    <!--        </v-col>-->
                  </v-row>
                  <!-- จบซัพพลายเออร์ -->

                  <!--                  <hr class="mb-3 mt-8"/>-->

                  <!-- เงื่อนไข -->
                  <!--      <h3>เงื่อนไขราคา</h3>-->
                  <!--      <v-row class="mt-2">-->
                  <!--        &lt;!&ndash; สกุลเงิน &ndash;&gt;-->
                  <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
                  <!--          <h4>สกุลเงิน</h4>-->
                  <!--        </v-col>-->
                  <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
                  <!--          <v-select :items="currency" label="เลือกสกุลเงิน" outlined hide-details></v-select>-->
                  <!--        </v-col>-->
                  <!--        &lt;!&ndash; เครดิต &ndash;&gt;-->
                  <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
                  <!--          <h4>เครดิต</h4>-->
                  <!--        </v-col>-->
                  <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
                  <!--          <v-select :items="credit" label="เลือกเครดิต" outlined hide-details></v-select>-->
                  <!--        </v-col>-->
                  <!--        &lt;!&ndash; ภาษีมูลค่าเพิ่ม &ndash;&gt;-->
                  <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
                  <!--          <h4>ภาษีมูลค่าเพิ่ม</h4>-->
                  <!--        </v-col>-->
                  <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
                  <!--          <v-select :items="tax" label="เลือกภาษี" outlined hide-details></v-select>-->
                  <!--        </v-col>-->
                  <!--        &lt;!&ndash; ส่วนลด &ndash;&gt;-->
                  <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
                  <!--          <h4>ส่วนลด</h4>-->
                  <!--        </v-col>-->
                  <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
                  <!--          <v-select :items="discount" label="เลือกส่วนลด" outlined hide-details></v-select>-->
                  <!--        </v-col>-->
                  <!--      </v-row>-->
                  <!-- จบเงื่อนไข -->

                  <hr class="mb-3 mt-3" />

                  <!-- สินค้า -->
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <h3>รายการ</h3>
                      <p class="mb-0">
                        รายการใบสั่งซื้อสินค้า Purchase Order
                      </p>
                    </div>
                    <!--                <v-dialog transition="dialog-top-transition" width="500">-->
                    <!--                  <template v-slot:activator="{ on, attrs }">-->
                    <!--                    <v-btn color="primary" text v-bind="attrs" v-on="on" class="me-2">-->
                    <!--                      เพิ่มโปรเจกต์-->
                    <!--                    </v-btn>-->
                    <!--                  </template>-->
                    <!--                  <template v-slot:default="dialog">-->
                    <!--                    <v-card>-->
                    <!--                      <v-toolbar color="primary">-->
                    <!--                        <p class="mb-0">เพิ่มโปรเจกต์</p>-->
                    <!--                      </v-toolbar>-->
                    <!--                      <v-card-text class="mt-5">-->
                    <!--                        <p class="mb-2">กรอกชื่อโปรเจกต์</p>-->
                    <!--                        <v-text-field-->
                    <!--                          v-model="projects"-->
                    <!--                          outlined-->
                    <!--                          dense-->
                    <!--                          hide-details-->
                    <!--                          label="ชื่อโปรเจกต์"-->
                    <!--                          placeholder="กรอกชื่อโปรเจกต์"-->
                    <!--                          class="mt-2 mb-3"-->
                    <!--                        ></v-text-field>-->
                    <!--                      </v-card-text>-->
                    <!--                      <v-card-actions class="justify-end">-->
                    <!--                        <v-btn color="error" text @click="dialog.value = false">ยกเลิก</v-btn>-->
                    <!--                        <v-btn color="primary" @click="dialog.value = false">สร้าง</v-btn>-->
                    <!--                      </v-card-actions>-->
                    <!--                    </v-card>-->
                    <!--                  </template>-->
                    <!--                </v-dialog>-->
                  </div>
                  <v-dialog
                    v-model="dialogAddProduct"
                    max-width="500px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="float: right"
                        color="secondary"
                        dark
                        class="mb-3"
                        v-bind="attrs"
                        @click="closeAddProduct"
                        v-on="on"
                      >
                        เพิ่มรายการสินค้า
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">เพิ่มรายการสินค้า</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-select
                                v-model="amount_product"
                                :items="['',1,5,10,20,50,100,500,1000]"
                                label="สินค้าน้อยกว่า"
                                @change="getPurchaseProduct(amount_product,type)"
                              >
                              </v-select>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <v-radio-group
                                v-model="type"
                                label="ประเภทสินค้า"
                                @change="getPurchaseProduct(amount_product,type)"
                              >
                                <v-radio
                                  value="1"
                                  label="ออนไลน์"
                                ></v-radio>
                                <v-radio
                                  value="2"
                                  label="ภายใน"
                                ></v-radio>
                                <span style="color: red;">*ใช้ในกรณีค้นสินค้าเพื่อเพิ่มรายการสินค้า</span>
                              </v-radio-group>
                            </v-col>

                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                            >
                              <!--                              <v-select-->
                              <!--                                v-model="product_id"-->
                              <!--                                label="ค้นหาสินค้า"-->
                              <!--                                item-value="id"-->
                              <!--                                item-text="product_name"-->
                              <!--                                :items="purchase_products">-->
                              <!--                              </v-select>-->

                              <v-select
                                v-model="product_id"
                                label="ค้นหาสินค้า"
                                item-value="id"
                                item-text="product_name"
                                :items="purchase_products"
                                outlined
                                dense
                                hide-details
                              >
                                <template v-slot:prepend-item>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-text-field
                                        v-model="searchTerm"
                                        placeholder="Search"
                                        @input="getPurchaseProduct(amount_product,type)"
                                      ></v-text-field>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="mt-2"></v-divider>
                                </template>
                              </v-select>
                              <span style="color: red;">*ใช้ในกรณีค้นสินค้าเพื่อเพิ่มรายการสินค้า</span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          text
                          @click="closeAddProduct"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="primary"
                          text

                          @click="selectProduct"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <table
                    class="table table-hover"
                    style="width: 100%"
                  >
                    <thead>
                      <tr>
                        <!--          <th scope="col" style="text-align: left;width: 5%">-->
                        <!--            <br>-->
                        <!--            &lt;!&ndash;              <input v-model="selectAll" type="checkbox"&ndash;&gt;-->
                        <!--            &lt;!&ndash;                         title="เลือกทั้งหมด"&ndash;&gt;-->
                        <!--            &lt;!&ndash;                         v-on:click="selectAllFunction">&ndash;&gt;-->
                        <!--          </th>-->
                        <th
                          scope="col"
                          style="text-align: center;"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          style="text-align: center;"
                        >
                          ประเภท
                        </th>
                        <th
                          scope="col"
                          style="text-align: center"
                        >
                          รายการ
                        </th>
                        <th
                          scope="col"
                          style="text-align: left;width: 10%"
                        >
                          ราคาหน่วย
                        </th>
                        <th
                          scope="col"
                          style="text-align: left;width: 10%"
                        >
                          จำนวน
                        </th>
                        <th
                          scope="col"
                          style="text-align: left;width: 10%"
                        >
                          ส่วนลด
                        </th>
                        <th
                          scope="col"
                          style="text-align: right;width: 10%"
                        >
                          จำนวนเงิน
                        </th>
                        <th
                          scope="col"
                          style="text-align: center;width: 10%"
                        >
                          เครื่องมือ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(list,index) in details">
                        <!--          <td><input v-if="list.product_id" v-model="selected" title="เลือก"-->
                        <!--                     :value="list.product_id" type="checkbox">-->
                        <!--          </td>-->
                        <td style="text-align: center">
                          {{ index + 1 }}
                        </td>
                        <td style="text-align: center">
                          <span v-if="list.product_id">
                            <v-checkbox
                              v-model="list.type_online"
                              dense
                              hide-details
                              label="ออนไลน์"
                            ></v-checkbox>
                            <!--            change="typeProduct()-->
                            <v-checkbox
                              v-model="list.type_in"
                              dense
                              hide-details
                              label="ภายใน"
                            ></v-checkbox>
                          </span>

                          <span v-if="!list.product_id">
                            <v-checkbox
                              v-model="list.type_online"
                              dense
                              hide-details
                              label="ออนไลน์"
                            ></v-checkbox>
                            <!--            change="typeProduct()-->
                            <v-checkbox
                              v-model="list.type_in"
                              dense
                              hide-details
                              value="2"
                              label="ภายใน"
                            ></v-checkbox>
                          </span>

                        <!--            <label v-if="list.product_id" for="checkbox"-->
                        <!--                   style="font-size: 11px"-->
                        <!--                   class="form-check-label"-->
                        <!--                   v-on:change="typeProduct()"><input-->
                        <!--              type="checkbox" name="type_online" id="type"-->
                        <!--              class="form-check-input" v-model="list.type_online"-->
                        <!--            >  </label>-->
                        <!--            <label v-if="list.product_id" for="checkbox"-->
                        <!--                   style="font-size: 11px"-->
                        <!--                   class="form-check-label"-->
                        <!--                   v-on:change="typeProduct()">-->
                        <!--              <v-checkbox label="ออนไลน์"></v-checkbox>-->
                        <!--              <input-->
                        <!--                value="2"-->
                        <!--              type="checkbox" name="type_in"-->
                        <!--              class="form-check-input" v-model="list.type_in"-->
                        <!--            >-->
                        <!--              ภายใน </label>-->
                        </td>
                        <td>
                          <span v-if="list.product_id">
                            {{
                              list.product && list.product.product_name ? list.product.product_name : list.product_name
                            }}
                          </span>
                          <span v-if="!list.product_id"></span>
                        </td>
                        <td>
                          <v-text-field
                            v-if="list.product_id"
                            v-model="list.product ? list.product.product_costs : list.product_costs"
                            placeholder="กรุณากรอกราคา"
                            inline
                            type="number"
                          ></v-text-field>

                          <v-text-field
                            v-if="!list.product_id"
                            v-model="list.product ? list.product.product_costs : list.product_costs"
                            placeholder="กรุณากรอกราคา"
                            inline
                            type="number"
                          ></v-text-field>

                        <!--            <span v-if="!list.product_costs"-->
                        <!--                  v-bind:style="list.product_costs ? '' : 'color: red;'">*กรุณากรอกราคา</span>-->
                        </td>
                        <td>
                          <v-text-field
                            v-if="list.product_id"
                            v-model="list.amount"
                            type="number"
                            placeholder="จำนวน"
                          ></v-text-field>
                        </td>

                        <td>
                          <v-text-field
                            v-if="list.product_id"
                            v-model="list.discount"
                            type="number"
                            :max="list.product_costs*list.amount"
                            placeholder="ส่วนลด"
                          ></v-text-field>
                        </td>
                        <td>
                          <b><span
                            v-if="list.product_id"
                          >
                            {{
                              parseFloat(((list.product ? list.product.product_costs : list.product_costs) * list.amount) - list.discount).toFixed(2)
                            }}
                          <!--              {{parseFloat(( (list.product ? list.product.product_costs : list.product_costs) * (list.product ? list.product.amount : list.amount) - list.discount)).toFixed(2) }}-->
                          </span></b>
                        </td>
                        <td class="text-center">
                          <v-icon
                            small
                            @click="deleteItemProduct(index)"
                          >
                            {{ icons.mdiDelete }}
                          </v-icon>

                        <!--            <button v-if="!list.product_id" type="button"-->
                        <!--                    :disabled="list.disabled"-->
                        <!--                    class="btn btn-warning" v-on:click="addProduct(index)"-->
                        <!--                    title="เพิ่มสินค้า">-->
                        <!--              เพิ่มสินค้า <i class="fas fa-plus-circle"></i>-->
                        <!--            </button>-->
                        </td>
                      </tr>
                      <tr v-if="details && details.length > 0">
                        <th
                          colspan="8"
                          style="text-align: right;"
                        >
                          <v-checkbox
                            v-model="editedItem.is_vat"
                            dense
                            style="float: right"
                            color="white"
                            hide-details
                            value="Y"
                            label="คิดภาษีมูลค่าเพิ่ม (VAT 7%)"
                          ></v-checkbox>
                        </th>
                      </tr>
                      <small
                        v-for="(err,index) in v$.details.$silentErrors"
                        v-if="v$.details.$error"
                        class="validate-err"
                      >{{ index > 0 ? ', ' : '' }}
                        {{ err.$message }}</small>

                      <tr v-if="details && details.length > 0">
                        <th
                          style="text-align: right"
                          colspan="4"
                        ></th>
                        <th
                          style="text-align: right"
                          colspan="3"
                        >
                          รวมเป็นเงินทั้งสิ้น
                        </th>
                        <th style="text-align: right">
                          {{ parseFloat(subTotal()).toFixed(2) }}
                        </th>
                      </tr>
                      <tr v-if="details && details.length > 0">
                        <th
                          style="text-align: right"
                          colspan="4"
                        ></th>
                        <th
                          style="text-align: right"
                          colspan="3"
                        >
                          หลักส่วนลดพิเศษ
                        </th>
                        <th style="text-align: right">
                          <v-text-field
                            v-model="editedItem.discount"
                            type="number"
                            :max="parseFloat(subTotal()).toFixed(2)"
                            placeholder="ส่วนลด"
                            size="small"
                            inline
                          ></v-text-field>
                        </th>
                      </tr>
                      <tr v-if="details && details.length > 0">
                        <th
                          style="text-align: right"
                          colspan="4"
                        ></th>
                        <th
                          style="text-align: right"
                          colspan="3"
                        >
                          ยอดรวมหักหลังส่วนลด
                        </th>
                        <th style="text-align: right">
                          {{
                            parseFloat(subTotal()).toFixed(2) === parseFloat(editedItem.discount).toFixed(2) ? 0.00 : afterDiscount()
                          }}
                        </th>
                      </tr>
                      <tr v-if="details && details.length > 0">
                        <th
                          style="text-align: right"
                          colspan="4"
                        ></th>
                        <th
                          style="text-align: right"
                          colspan="3"
                        >
                          จำนวนภาษีมูลค่าเพิ่ม 7 %
                        </th>
                        <th style="text-align: right">
                          {{
                            editedItem.is_vat === 'Y' ? parseFloat(afterDiscount() - VatTotal()).toFixed(2) : 0.00
                          }}
                        </th>
                      </tr>
                      <tr
                        v-if="details && details.length > 0"
                        style="background-color: black;color: white;font-weight: 700;font-size: 24px"
                      >
                        <th
                          style="text-align: right"
                          colspan="3"
                        ></th>
                        <th
                          style="text-align: right;;color: white"
                          colspan="4"
                        >
                          <h3
                            style="color: white"
                          >
                            จำนวนเงินรวมทั้งสิ้น
                          </h3>
                        </th>
                        <th style="text-align: right;color: white">
                          <h3 style="color: white">
                            <div v-if="editedItem.is_vat === 'Y'">
                              {{
                                parseFloat(subTotal()).toFixed(2) !== parseFloat(editedItem.discount).toFixed(2) ? parseFloat((subTotal() - editedItem.discount) + (afterDiscount() - VatTotal())).toFixed(2) : ''
                              }}
                            </div>
                            <div v-if="editedItem.is_vat !== 'Y'">
                              {{
                                parseFloat(subTotal()).toFixed(2) === parseFloat(editedItem.discount).toFixed(2) ? 0.00 : parseFloat((subTotal() - editedItem.discount)).toFixed(2)
                              }}
                            </div>
                          </h3>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                  <!--      <v-data-table :headers="headers" :items="editedItem.details" sort-by="calories" class="elevation-1 mt-4">-->
                  <!--        <template v-slot:top>-->
                  <!--          <v-toolbar flat>-->
                  <!--            <v-divider class="mx-4" inset vertical></v-divider>-->
                  <!--            <v-spacer></v-spacer>-->
                  <!--            <v-dialog v-model="dialog" max-width="500px">-->
                  <!--              <template v-slot:activator="{ on, attrs }">-->
                  <!--                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">-->
                  <!--                  เพิ่มรายการสินค้าสินค้า-->
                  <!--                </v-btn>-->
                  <!--              </template>-->
                  <!--              <v-card>-->
                  <!--                <v-card-title>-->
                  <!--                  <span class="text-h5">{{ formTitle }}</span>-->
                  <!--                </v-card-title>-->

                  <!--                <v-card-text>-->
                  <!--                  <v-container>-->
                  <!--                    <v-row>-->
                  <!--                      <v-col cols="12" sm="12" md="12">-->
                  <!--                        <v-select-->
                  <!--                          @change="getPurchaseProduct(amount_product,type)"-->
                  <!--                          v-model="amount_product"-->
                  <!--                          :items="[1,5,10,20,50,100,500,1000]"-->
                  <!--                          label="สินค้าน้อยกว่า">-->

                  <!--                        </v-select>-->
                  <!--                      </v-col>-->
                  <!--                      <v-col cols="12" sm="12" md="12">-->
                  <!--                        <v-radio-group-->
                  <!--                          v-model="type"-->
                  <!--                          @change="getPurchaseProduct(amount_product,type)"-->
                  <!--                          label="ประเภทสินค้า">-->
                  <!--                          <v-radio value="1" label="ออนไลน์"></v-radio>-->
                  <!--                          <v-radio value="2" label="ภายใน"></v-radio>-->
                  <!--                        </v-radio-group>-->
                  <!--                      </v-col>-->

                  <!--                      <v-col cols="12" sm="12" md="12">-->
                  <!--                        <v-select-->
                  <!--                          v-model="product_id"-->
                  <!--                          label="ค้นหาสินค้า"-->
                  <!--                          item-value="id"-->
                  <!--                          item-text="product_name"-->
                  <!--                          :items="purchase_products">-->
                  <!--                        </v-select>-->
                  <!--                      </v-col>-->
                  <!--                    </v-row>-->
                  <!--                  </v-container>-->
                  <!--                </v-card-text>-->

                  <!--                <v-card-actions>-->
                  <!--                  <v-spacer></v-spacer>-->
                  <!--                  <v-btn color="error" text @click="close">-->
                  <!--                    Cancel-->
                  <!--                  </v-btn>-->
                  <!--                  <v-btn color="primary" text @click="selectProduct">-->
                  <!--                    Save-->
                  <!--                  </v-btn>-->
                  <!--                </v-card-actions>-->
                  <!--              </v-card>-->
                  <!--            </v-dialog>-->
                  <!--            <v-dialog v-model="dialogDelete" max-width="500px">-->
                  <!--              <v-card>-->
                  <!--                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>-->
                  <!--                <v-card-actions>-->
                  <!--                  <v-spacer></v-spacer>-->
                  <!--                  <v-btn color="error" text @click="closeDelete">Cancel</v-btn>-->
                  <!--                  <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>-->
                  <!--                  <v-spacer></v-spacer>-->
                  <!--                </v-card-actions>-->
                  <!--              </v-card>-->
                  <!--            </v-dialog>-->
                  <!--          </v-toolbar>-->
                  <!--        </template>-->
                  <!--        <template v-slot:item.actions="{ item }">-->
                  <!--          <v-icon small class="mr-2" @click="editItem(item)">-->
                  <!--            {{ icons.mdiPencil }}-->
                  <!--          </v-icon>-->
                  <!--          <v-icon small @click="deleteItem(item)">-->
                  <!--            {{ icons.mdiDelete }}-->
                  <!--          </v-icon>-->
                  <!--        </template>-->
                  <!--        <template v-slot:item.index="{ index }">-->
                  <!--         {{index +1 }}-->
                  <!--        </template>-->
                  <!--        <template v-slot:no-data>-->
                  <!--          <v-btn color="primary" @click="initialize">-->
                  <!--            Reset-->
                  <!--          </v-btn>-->
                  <!--        </template>-->
                  <!--      </v-data-table>-->
                  <!--      <p class="text-end mb-0 mt-3 font-weight-bold">-->
                  <!--        ยอดรวม <span class="font-weight-regular ms-5">{{ editedItem.sub_total }}</span>-->
                  <!--      </p>-->
                  <!--      <p class="text-end mb-0 font-weight-bold">-->
                  <!--        ยอดเงินสุทธิ <span class="font-weight-regular ms-5">{{ editedItem.total }}</span>-->
                  <!--      </p>-->
                  <!--      <v-row>-->
                  <!--        <v-col cols="12" sm="6" md="4">-->
                  <!--          <p class="mb-0">หมายเหตุเอกสาร</p>-->
                  <!--        </v-col>-->
                  <!--        <v-col cols="12" sm="6" md="8">-->
                  <!--          <p @click="showTextarea" class="mb-0 pTagDynamic">เพิ่มหมายเหตุ</p>-->
                  <!--          <v-textarea v-if="textAreaContent" outlined hide-details label="เพิ่มหมายเหตุ"-->
                  <!--                      :value="note"></v-textarea>-->
                  <!--        </v-col>-->
                  <!--      </v-row>-->
                  <!-- จบสินค้า -->

                  <hr class="mt-3 mb-3" />

                  <!-- เอกสาร และโน้ต-->
                  <h3>เอกสารแนบ และโน้ต</h3>
                  <p class="mb-0">
                    เพิ่มเอกสาร หรือข้อความแนบสำหรับใบสั่งซื้อนี้
                  </p>
                  <v-row class="mt-2">
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <h4 class="mb-0 text-end">
                        โน้ต
                      </h4>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="8"
                    >
                      <v-textarea
                        v-model="editedItem.note"
                        outlined
                        hide-details
                        label="โน้ตอื่น ๆ เพื่อใช้ในบริษัท"
                      ></v-textarea>
                      <!--          round-->
                      <!--          <v-btn color="primary" class="text-none mt-3" depressed :loading="isSelecting"-->
                      <!--                 @click="onButtonClick">-->
                      <!--            <v-icon left>-->
                      <!--              {{ icons.mdiCloudUpload }}-->
                      <!--            </v-icon>-->
                      <!--            {{ buttonText }}-->
                      <!--          </v-btn>-->
                      <!--                      <input ref="uploader" class="d-none" type="file" @change="onFileChanged"/>-->
                    </v-col>
                  </v-row>
                  <!-- จบเอกสาร และโน๊ต -->
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    color="error"
                    text
                    @click="close()"
                  >
                    ยกเลิก
                  </v-btn>
                  <v-btn
                    v-if="editedItem.status != 'Y' && details && details.length > 0"
                    title="บันทึกร่าง"
                    color="secondary"
                    @click="save('draft')"
                  >
                    บันทึกฉบับร่าง
                  </v-btn>

                  <!--      <v-btn-->
                  <!--        title="สินค้าค้างรับ"-->
                  <!--        v-if="purchase_order_type === 2 && editedItem.status != 'Y' && editedItem.details.length > 0"-->
                  <!--        color="primary" @click="storePurchase('Not complete')">สินค้าค้างรับ</v-btn>-->

                  <v-btn
                    v-if="editedItem.status != 'Y' && details && details.length > 0"
                    color="primary"
                    @click="save('save')"
                  >
                    บันทึก
                  </v-btn>
                </v-card-actions>
              </v-card>
              <!--                <CreateOrder-->

              <!--                  :purchase_products="purchase_products"-->
              <!--                  :getPurchaseOrder="getPurchaseOrder"-->
              <!--                  :getPurchaseProduct="getPurchaseProduct"-->
              <!--                  :close="close"-->
              <!--                  :save="save"-->
              <!--                  :v$="v$"-->
              <!--                  :payments="payments"-->
              <!--                  :data="editedItem"/>-->
            </v-card>
          </template>
        </v-dialog>

        <v-dialog
          v-model="dialogDelete"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-h5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!--      </div>-->
      <v-row class="mt-5">
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <v-text-field
            v-model="search"
            :prepend-inner-icon="icons.mdiMagnify"
            label="ค้นหา"
            type="text"
            outlined
            dense
            hide-details
            placeholder="ค้นหา"
          ></v-text-field>
        </v-col>
        <v-col
          md="4"
          sm="6"
          cols="12"
        >
          <v-select
            v-model="status"
            :prepend-inner-icon="icons.mdiFilter"
            dense
            outlined
            hide-details
            label="ตัวกรอง"
            item-value="value"
            item-text="label"
            :items="filter"
          ></v-select>
        </v-col>
        <!--        <v-col md="4" sm="6" cols="12">-->
        <!--          <v-select-->
        <!--            :prepend-inner-icon="icons.mdiFileDocument"-->
        <!--            dense-->
        <!--            hide-details-->
        <!--            outlined-->
        <!--            label="ใบสั่งซื้อ"-->
        <!--            :items="filterBill"-->
        <!--          ></v-select>-->
        <!--        </v-col>-->
      </v-row>
      <!-- ตารางสถานะต่าง ๆ -->
      <v-data-table
        v-model="selected"
        :search="search"
        :headers="headers"
        :items="purchase_orders"
        :server-items-length="total"
        :options.sync="serverOptions"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        item-key="name"
        class="elevation-1 mt-5"
      >
        <template v-slot:item.index="{ index }">
          {{ ((serverOptions.page-1)*serverOptions.itemsPerPage) + index +1 }}
        </template>
        <template v-slot:item.status="{ item }">
          <span
            v-if="item.status == 'N'"
            class="warning-badge"
          >ฉบับร่าง</span>
          <span
            v-if="item.status == 'W'"
            class="danger-badge"
          >สินค้าค้างรับ</span>
          <span
            v-if="item.status == 'Y'"
            class="success-badge"
          >เรียบร้อย</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="print(item)"
          >
            {{ icons.mdiPrinter }}
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            {{ icons.mdiDelete }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiCartMinus,
  mdiMagnify,
  mdiTruck,
  mdiPrinterSearch,
  mdiPlusCircle,
  mdiFilter,
  mdiFileDocument,
  mdiPencil,
  mdiDelete,
  mdiPrinter,
} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Swal from 'sweetalert2'
import { minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import CreateOrder from '../page-createBill/CreateOrder.vue'
import instance_purchase_order from '../../../services/purchaseOrder'
import instance_pickup from '@/services/purchaseOrder'
import instance_payments from '../../../services/payment'

export default {
  components: {
    DatePicker, CreateOrder, mdiPencil, mdiDelete, mdiPrinter,
  },
  setup() {
    const filter = [
      {
        label: 'ฉบับร่าง',
        value: 'N',
      },
      {
        label: 'สินค้าค้างรับ',
        value: 'W',
      },
      {
        label: 'เรียบร้อย',
        value: 'Y',
      }]
    const filterBill = ['ใบสั่งซื้อ', 'ซื้อซัพพลายเออร์', 'สินค้า', 'ชื่อโปรเจกต์', 'โน็ต']

    return {
      v$: useVuelidate(),
      icons: {
        mdiCartMinus,
        mdiMagnify,
        mdiTruck,
        mdiPrinterSearch,
        mdiPlusCircle,
        mdiFilter,
        mdiFileDocument,
        mdiPencil,
        mdiDelete,
        mdiPrinter,
      },
      filter,
      filterBill,
    }
  },
  data() {
    return {
      selected_address: null,
      address: [],
      searchTerm: '',
      serverOptions: {
        page: 1,
        itemsPerPage: 10,
      },
      total: 0,
      status: '',
      type_in: false,
      type_online: false,
      type_all: false,
      details: [],
      amount_product: '',
      product_id: '',
      type: '',
      search: '',
      time1: null,
      time2: null,
      time3: null,
      singleSelect: null,
      sortBy: null,
      sortDesc: null,
      selected: [],
      dataOpenUser: false,
      headers: [
        { text: 'ลำดับ', value: 'index', sortable: false },

        // {
        //   text: 'ใบสั่งซื้อ',
        //   align: 'start',
        //   sortable: false,
        //   value: 'numberBill',
        // },
        { text: 'ชื่อผู้ขาย', value: 'supplier_name' },
        { text: 'เลขที่ใบสั่งซื้อสินค้า', value: 'purchase_on' },
        { text: 'วันที่', value: 'date' },
        { text: 'ชื่อผู้ติดต่อ', value: 'contact_name' },
        { text: 'ล่าสุด', value: 'updated_at' },
        { text: 'พนักงาน', value: 'employee.employee_name', sortable: false },
        { text: 'สถานะ', value: 'status' },
        { text: 'เครื่องมือ', value: 'actions', sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      dialogAddProduct: false,
      purchase_orders: [],
      payments: [],
      purchase_products: [],
      editedIndex: -1,
      editedItem: {
        purchase_on: '',
        updated_at: '',
        date: this.nowDate(),
        supplier_name: '',
        contact_name: '',
        address: '',
        payment_id: '',
        project_name: '',
        employee: '',
        employee_id: '',
        note: '',
        tax_id: '',
        discount: '',
        payment_type: '',
        status: '',
        vat: '',
        sub_total: '',
        total: '',
        purchase_order_type: 1,
        details: [],
      },
      defaultItem: {
        purchase_on: '',
        updated_at: '',
        date: this.nowDate(),
        supplier_name: '',
        contact_name: '',
        address: '',
        payment_id: '',
        project_name: '',
        employee: '',
        employee_id: '',
        note: '',
        tax_id: '',
        discount: '',
        payment_type: '',
        status: '',
        vat: '',
        sub_total: '',
        total: '',
        purchase_order_type: 1,
        details: [],
      },
    }
  },
  watch: {
    serverOptions: {
      handler() {
        this.getPurchaseOrder()
      },
    },
    search: {
      handler() {
        this.getPurchaseOrder()
      },
    },
    status: {
      handler() {
        this.getPurchaseOrder()
      },
    },
  },
  validations() {
    return {
      editedItem: {
        supplier_name: { required },
        tax_id: { required },
        address: { required },
        payment_id: { required },
      },
      details: {
        required,
        minLength: minLength(1),
      },
    }
  },
  mounted() {
    this.getPurchaseOrder()
    this.getPurchaseProduct()
    this.getPayment()
    this.getAddressData()
  },
  methods: {
    getAddressData() {
      instance_purchase_order.getAddress(1).then(res => {
        this.address = res.data.data
      }).catch(err => {

      })
    },
    address_select() {
      let data = null
      Object.entries(this.address).forEach(([key, val]) => {
        if (val.address == this.selected_address) {
          data = val
        }
      })

      if (data && data.address) {
        this.editedItem.address = data.address
      }
      if (data && data.tax_id) {
        this.editedItem.tax_id = data.tax_id
      }
      if (data && data.supplier_name) {
        this.editedItem.supplier_name = data.supplier_name
      }
      this.$nextTick(() => {
        this.selected_address = null
      })
    },
    typeProduct() {
      if (this.type_in && this.type_online) {
        this.type_all = true
        this.type_in = false
        this.type_online = false
      } else {
        this.type_all = false
      }
    },
    subTotal() {
      const total = []
      Object.entries(this.details).forEach(([key, val]) => {
        let sum = 0
        if (val.product_id) {
          const product_costs = val.product ? val.product.product_costs : val.product_costs
          const { amount } = val
          const discount = parseFloat(val.discount)
          if ((product_costs * amount) > discount) {
            sum = (product_costs * amount) - discount
          } else {
            sum = (product_costs * amount)
          }
        }

        total.push(parseFloat(sum).toFixed(2))
      })

      return total.reduce((total, num) => parseFloat(total) + parseFloat(num), 0)
    },
    afterDiscount() {
      const subTotal = this.subTotal()
      const { discount } = this.editedItem

      return parseFloat(subTotal - discount).toFixed(2)
    },
    VatTotal() {
      const afterDiscount = this.afterDiscount()
      const vat = parseFloat((afterDiscount * 7) / 100).toFixed(2)

      return parseFloat(afterDiscount - vat).toFixed(2)
    },
    nowDate() {
      const date = new Date()
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      const d = `${year}-${String(month).padStart(2, '0')}-${day}`

      return d
    },
    close() {
      this.v$.$reset()

      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.selected_address = null
      })
      this.editedIndex = -1
      this.details = []
      this.dialog = false
    },
    selectProduct(item) {
      let check = true
      Object.entries(this.purchase_products).forEach(([key, val]) => {
        if (val.id == this.product_id) {
          this.product_id = val

          return false
        }
      })

      Object.entries(this.details).forEach(([key, val]) => {
        if (val.product_code === this.product_id.product_code) {
          if (this.product_id.type_product === 1) {
            this.details[key].product_amount_online = this.details.product_amount
          }
          if (this.product_id.type_product === 2) {
            this.details[key].product_amount = this.product_id.product_amount
          }
          check = false
          Swal.fire({
            icon: 'error',
            title: 'สินค้ามีอยู่ในรายการเเล้ว',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })

      if (check) {
        const product_select = this.product_id
        if (product_select.type_product === 1) {
          product_select.product_amount_online = this.product_id.product_amount_online
        }
        if (product_select.type_product === 2) {
          product_select.product_amount = this.product_id.product_amount
        }
        product_select.type_in = true
        product_select.type_online = true

        this.details.push(product_select)

        // this.checkParentProduct();
        this.addProductCheckValidate()
      }

      // var tmp = {
      //   id : '',
      //   price : 0,
      //   product : this.product_id,
      //   product_id : this.product_id.product_id,
      //   type : 'All',
      //   purchase_order_id : '',
      //   amount : 0,
      //   discount : 0,
      // }
      // this.editedItem.details.push(tmp)
      this.dialogAddProduct = false
    },
    addProductCheckValidate() {
      Object.entries(this.details).forEach(([key, val]) => {
        if (!val.product_id && (!val.product_name || !val.product_costs)) {
          this.count += 1
        }
      })
      if (this.count > 1) {
        this.details.splice(-1)

        Swal.fire({
          icon: 'error',
          title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    },
    async save(status) {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true
      let total = 0
      if (this.editedItem.is_vat === 'Y') {
        total = parseFloat((this.subTotal() - this.editedItem.discount) + (this.afterDiscount() - this.VatTotal())).toFixed(2)
      } else {
        total = parseFloat((this.subTotal() - this.editedItem.discount)).toFixed(2)
      }

      this.editedItem.sub_total = this.subTotal()
      this.editedItem.total = total
      this.editedItem.details = this.details

      if (this.editedIndex > -1) {
        instance_purchase_order.purchaseUpdate(this.editedItem, status).then(res => {
          if (res.data.success) {
            this.getPurchaseOrder()
            this.close()
          } else {
            Swal.fire({
              icon: 'error',
              text: res.data.messages,
              showConfirmButton: false,
              timer: 1500,
            })
            this.$store.state.isLoading = false
          }
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            text: err.message,
            showConfirmButton: false,
            timer: 1500,
          })
          this.$store.state.isLoading = false
        })
      } else {
        this.editedItem.create = true
        instance_purchase_order.purchaseStore(this.editedItem, status).then(res => {
          if (res.data.success) {
            this.getPurchaseOrder()
            this.close()
          } else {
            Swal.fire({
              icon: 'error',
              text: res.data.messages,
              showConfirmButton: false,
              timer: 1500,
            })
            this.$store.state.isLoading = false
          }
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            text: err.message,
            showConfirmButton: false,
            timer: 1500,
          })
          this.$store.state.isLoading = false
        })
      }
    },
    print(item) {
      const url = `/order_print/${item.id}?purchase_order_type=1`
      window.open(url, '_blank', 'noreferrer')
    },
    editItem(item) {
      this.$store.state.isLoading = true
      this.editedIndex = this.purchase_orders.indexOf(item)
      this.editedItem = { ...item }
      instance_purchase_order.getPurchaseOrderDetail(this.editedItem.id, 1)
        .then(res => {
          // this.editedItem = res.data.purchase_orders;
          this.details = res.data.purchase_order_details

          // if (this.data.is_vat === 'Y') {
          //   this.data.is_vat = true;
          // } else {
          //   this.data.is_vat = false;
          // }
          Object.entries(this.details).forEach(([key, val]) => {
            if (val.type === 'All') {
              this.details[key].type_online = true
              this.details[key].type_in = true
            } else if (val.type === 'Online') {
              this.details[key].type_online = true
              this.details[key].type_in = false
            } else {
              this.details[key].type_online = false
              this.details[key].type_in = true
            }
          })

          // Object.entries($this.payment_types).forEach(([key, val]) => {
          //   if (val.id === $this.data.payment_id) {
          //     $this.data.payment_id = {'name': val.name, 'id': val.id}
          //     return false;
          //   }
          // });
          this.dialog = true
          this.$store.state.isLoading = false
        }).catch(err => {
          this.$store.state.isLoading = false
        })
    },
    closeAddProduct() {
      this.type = '1'
      this.product_id = ''
      this.amount_product = 1
      this.dialogAddProduct = false
    },
    deleteItem(item) {
      this.editedIndex = this.purchase_orders.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },
    deleteItemProduct(index) {
      this.details.splice(index, 1)

      // this.editedIndex = this.purchase_orders.indexOf(item)
      // this.editedItem = {...item}
      // this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.$store.state.isLoading = true
      instance_purchase_order.purchaseDelete(this.editedItem.id).then(res => {
        this.getPurchaseOrder()
        this.closeDelete()
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    getPurchaseOrder() {
      this.$store.state.isLoading = true
      const data = {
        search: this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        purchase_order_type: 1,
        status: this.status,
        sortBy: this.sortBy ?? '',
        sortDesc: this.sortDesc ?? '',
      }
      instance_purchase_order.get(data).then(res => {
        this.purchase_orders = res.data.data.data
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    getPurchaseProduct(amount = 1, type = 1) {
      const data = {
        type,
        amount,
        search: this.searchTerm ? this.searchTerm : '',
      }
      this.$store.state.isLoading = true
      instance_purchase_order.getPurchaseProduct(data).then(res => {
        this.purchase_products = res.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    getPayment() {
      instance_payments.get().then(res => {
        this.payments = res.data.data
      }).catch(err => {

      })
    },
  },
}
</script>

<style scoped>
.box_content {
  text-align: center;
  background-color: #f4f5f9;
  display: grid;
  align-items: center;
  padding: 10px 0;
  color: #8e8a98;
  font-weight: 600;
  font-size: 16px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.box_content.box-1 {
  background-color: #8dbc89;
  color: #ffffff;
  width: 21rem;
  height: 5rem;
}

.box_content.box-2 {
  background-color: dodgerblue;
  color: #ffffff;
  width: 10rem;
  height: 5rem;
}

.box_content.box-3 {
  color: #ffffff;
  background-color: coral;
  width: 10rem;
  height: 5rem;
}

.bg-header {
  background-color: #8dbc89;
}

.bg-header p {
  color: #f4f5f9;
  font-weight: 600;
  font-size: 16px;
}

.bg-header span {
  color: #fff8ff;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  text-decoration: underline;
}

.checkItem {
  width: 15px;
  height: 15px;
  margin-right: 1rem;
}

.picker-res {
  width: 100% !important;
}

.test {
  display: flex;
}

@media screen and (max-width: 600px) {
  .test {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .test2 {
    display: grid !important;
  }

  .mg-res {
    margin-top: 1.5rem;
    transform: translateX(-40%);
    left: 50%;
  }

  /* .picker-res {
    width: 100% !important;
  } */
  .box_content.box-1 {
    width: 5rem !important;
  }

  .box_content.box-2 {
    width: 5rem !important;
  }

  .box_content.box-3 {
    width: 5rem !important;
  }
}

@media screen and (max-width: 960px) {
  .box_content.box-1 {
    width: 8rem;
  }

  .box_content.box-2 {
    width: 8rem;
  }

  .box_content.box-3 {
    width: 8rem;
  }
}
</style>
