import api from '../axios.service'

export default {
  get(data) {
    return api.get(`purchase_order/list?search=${data.search}&purchase_order_type=${data.purchase_order_type}&size=${data.size}&page=${data.page}&status=${data.status}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  getAll(data) {
    return api.get(`purchase_order/all?search=${data.search}&purchase_order_type=${data.purchase_order_type}`)
  },
  getAddress(type){

    return api.get(`purchase_order/get_address?type=${type}`)
  },
  getPurchaseProduct(data) {
    return api.get(`purchase_order/getPurchaseProduct?type=${data.type}&amount=${data.amount}&search=${data.search}`)
  },
  // eslint-disable-next-line camelcase
  show(id,purchase_order_type = '') {
    // eslint-disable-next-line camelcase
    return api.get(`purchase_order/show/${id}?purchase_order_type=${purchase_order_type}`)
  },
  getPurchaseOrder(id,amount,type){
    return api.get(`purchase_order/get_purchase_order/${id}?amount=${amount}&type=${type}`)
  },
  getPurchaseOrderDetail(id,purchase_order_type = 1){
    return api.get(`purchase_order/show/${id}?purchase_order_type=${purchase_order_type}`)
  },
  purchaseStore(data,status){
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        if (key == 'details'){
          params.append('purchase_order_details', JSON.stringify(getFormData[key]));
        }else{
          params.append(key, getFormData[key]);
        }
      }
    }
    if (status === 'save'){
      params.append('status', 'Y');
    }else if(status === 'Not complete'){
      params.append('status', 'W');
    }else{
      params.append('status', 'N');
    }

    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`purchase_order/store`,params,config)
  },
  purchaseUpdate(data,status){
    let getFormData = data

    let params = new FormData();
    params.append('_method','PUT')
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        if (key == 'details'){
          params.append('purchase_order_details', JSON.stringify(getFormData[key]));
        }else{
          params.append(key, getFormData[key]);
        }
      }
    }

    if (status === 'save'){
      params.append('status', 'Y');
    }else if(status === 'Not complete'){
      params.append('status', 'W');
    }else{
      params.append('status', 'N');
    }

    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`purchase_order/update/${data.id}`,params,config)
  },
  purchaseDelete(id,purchase_order_type = 1){
    // let getFormData = data

    let params = new FormData();
    params.append('_method', "DELETE");
    params.append('purchase_order_type', purchase_order_type);

    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`purchase_order/delete/${id}`,params,config)
  },


}
