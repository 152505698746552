<template>
  <v-card flat>
    <v-card-text>
      <!-- ประเภทใบสั่งซื้อ -->
      <v-row>
        <v-col md="6" sm="6" cols="12">
          <p class="mb-0">ใบสั่งซื้อ: {{ editedItem ? editedItem.purchase_on : '-' }}</p>
        </v-col>
        <v-col md="6" sm="6" cols="12">
          <DatePicker
            value-type="format"
            format="YYYY-MM-DD"
            v-model="editedItem.date"
            placeholder="วันที่"
            label="วันที่"
            style="width: 100%"
          ></DatePicker>
          <!--          <v-menu-->
          <!--            ref="menu"-->
          <!--            v-model="editedItem.date"-->
          <!--            :close-on-content-click="false"-->
          <!--            :return-value.sync="date"-->
          <!--            transition="scale-transition"-->
          <!--            offset-y-->
          <!--            min-width="auto"-->
          <!--          >-->
          <!--            <template v-slot:activator="{ on, attrs }">-->
          <!--              &lt;!&ndash;              <v-text-field&ndash;&gt;-->
          <!--              &lt;!&ndash;                v-model="date"&ndash;&gt;-->
          <!--              &lt;!&ndash;                label="วันที่"&ndash;&gt;-->
          <!--              &lt;!&ndash;                prepend-icon="mdi-calendar"&ndash;&gt;-->
          <!--              &lt;!&ndash;                readonly&ndash;&gt;-->
          <!--              &lt;!&ndash;                v-bind="attrs"&ndash;&gt;-->
          <!--              &lt;!&ndash;                v-on="on"&ndash;&gt;-->
          <!--              &lt;!&ndash;              ></v-text-field>&ndash;&gt;-->


          <!--              &lt;!&ndash;              <v-date-picker></v-date-picker>&ndash;&gt;-->
          <!--            </template>-->
          <!--            &lt;!&ndash;            <v-date-picker v-model="editedItem.date" no-title scrollable>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <v-spacer></v-spacer>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <v-btn text color="primary" @click="menu = false">&ndash;&gt;-->
          <!--            &lt;!&ndash;                Cancel&ndash;&gt;-->
          <!--            &lt;!&ndash;              </v-btn>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <v-btn text color="primary" @click="$refs.menu.save(date)">&ndash;&gt;-->
          <!--            &lt;!&ndash;                OK&ndash;&gt;-->
          <!--            &lt;!&ndash;              </v-btn>&ndash;&gt;-->
          <!--            &lt;!&ndash;            </v-date-picker>&ndash;&gt;-->
          <!--          </v-menu>-->
        </v-col>
      </v-row>
      <!--      <hr class="mb-3" />-->
      <!--      <h3>ประเภทใบสั่งซื้อ</h3>-->
      <!--      <p>กรุณาเลือกประเภทของการสั่งซื้อ เพื่อการลงบัญชีที่ถูกต้อง <a href="#">เรียนรู้เพิ่มเติม</a></p>-->
      <!--      <v-radio-group v-model="radioGroup">-->
      <!--        <v-radio v-for="n in dataRadio" :key="n" :label="`${n}`" :value="n"></v-radio>-->
      <!--      </v-radio-group>-->
      <!-- จบประเภทใบสั่งซื้อ -->

      <hr class="mb-3 mt-3"/>

      <!-- ซัพพลายเออร์ -->
      <v-row>
        <v-col md="6" sm="6" cols="12">
          <h3>ซัพพลายเออร์</h3>
          <!--          <p class="mb-0">เพิ่มรายละเอียดของซัพพลายเออร์ <a href="#">เรียนรู้เพิ่มเติม</a></p>-->
        </v-col>
        <v-col md="6" sm="6" cols="12" class="d-flex justify-end">
          <!--          <v-dialog transition="dialog-top-transition" width="700">-->
          <!--            <template v-slot:activator="{ on, attrs }">-->
          <!--              <v-btn color="primary" text v-bind="attrs" v-on="on" class="me-2">-->
          <!--                <v-icon>{{ icons.mdiMagnify }}</v-icon>-->
          <!--                ข้อมูลกรมสรรพากร-->
          <!--              </v-btn>-->
          <!--            </template>-->
          <!--            <template v-slot:default="dialog">-->
          <!--              <v-card>-->
          <!--                <v-toolbar color="primary">-->
          <!--                  <p class="mb-0">ค้นหาข้อมุลบริษัทจากฐานข้อมูลกรมสรรพากร</p>-->
          <!--                </v-toolbar>-->
          <!--                <RevenueDepartment />-->
          <!--                <v-card-actions class="justify-end">-->
          <!--                  <v-btn text @click="dialog.value = false">Close</v-btn>-->
          <!--                </v-card-actions>-->
          <!--              </v-card>-->
          <!--            </template>-->
          <!--          </v-dialog>-->
        </v-col>
        <!-- ชื่อซัพพลายเออร์ -->
        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">
          <h4 class="text-end">ชื่อซัพพลายเออร์</h4>
        </v-col>
        <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="editedItem.supplier_name"
            outlined
            dense
            hide-details
            label="กรอกชื่อซัพพลายเออร์"
            placeholder="เพิ่มชื่อซัพพลายเออร์ในนามบุคคล หรือ นิติบุคคล"
            class="mt-2 mb-3"
          ></v-text-field>

          <small
            class="validate-err"
            v-for="(err,index) in v$.editedItem.supplier_name.$silentErrors"
            v-if="v$.editedItem.supplier_name.$error">{{ index > 0 ? ', ' : '' }}
            {{ err.$message }}</small>

        </v-col>
        <!-- เลขประจำตัวผู้เสียภาษี -->
        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-1 pb-0">
          <h4 class="text-end">เลขประจำตัวผู้เสียภาษี</h4>
        </v-col>
        <v-col md="8" sm="6" cols="12" class="pt-1 pb-0">
          <v-text-field
            v-model="editedItem.tax_id"
            outlined
            dense
            hide-details
            label="กรอกเลขประจำตัวผู้เสียภาษี"
            placeholder="เลขประจำตัวผู้เสียภาษี"
            class="mt-2 mb-3"
          ></v-text-field>
          <small
            class="validate-err"
            v-for="(err,index) in v$.editedItem.tax_id.$silentErrors"
            v-if="v$.editedItem.tax_id.$error">{{ index > 0 ? ', ' : '' }}
            {{ err.$message }}</small>
        </v-col>
        <!-- ที่อยู่ -->
        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">
          <h4 class="text-end">ที่อยู่</h4>
        </v-col>
        <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">
          <v-textarea
            class="mb-5"
            v-model="editedItem.address"
            outlined hide-details label="เพิ่มที่อยู่"></v-textarea>
          <small
            class="validate-err"
            v-for="(err,index) in v$.editedItem.address.$silentErrors"
            v-if="v$.editedItem.address.$error">{{ index > 0 ? ', ' : '' }}
            {{ err.$message }}</small>
        </v-col>
        <!-- ที่อยู่ -->
        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">
          <h4 class="text-end">ชื่อโปรเจ็ค</h4>
        </v-col>
        <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="editedItem.project_name"
            outlined
            dense
            hide-details
            label="ชื่อโปรเจ็ค"
            placeholder="ชื่อโปรเจ็ค"
            class="mt-2 mb-3"
          ></v-text-field>
          <!--          <v-radio-group v-model="radioGroup2">-->
          <!--            <v-radio v-for="n in buyForm" :key="n" :label="`${n}`" :value="n"></v-radio>-->
          <!--          </v-radio-group>-->
        </v-col>
        <!-- ชื่อซัพพลายเออร์ -->
        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">
          <h4 class="text-end">ชื่อผู้ติดต่อ</h4>
        </v-col>
        <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="editedItem.contact_name"
            outlined
            dense
            hide-details
            label="ชื่อผู้ติดต่อ"
            placeholder="ชื่อผู้ติดต่อ"
            class="mt-2 mb-3"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">
          <h4 class="text-end">ประเภทการชำระเงิน</h4>
        </v-col>
        <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">
          <v-select
            v-model="editedItem.payment_id"
            :items="payments"
            item-value="id"
            item-text="name"
          ></v-select>
        </v-col>
        <!-- ข้อมูลผู้ติดต่อ -->
        <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-0">-->
        <!--          <h4 class="text-end">ข้อมูลผู้ติดต่อ</h4>-->
        <!--        </v-col>-->
        <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-0">-->
        <!--          <p v-if="!showData" @click="clickShowData" class="mb-0 pTagDynamic">แสดงข้อมูล</p>-->
        <!--          <p v-if="showData" @click="clickShowData" class="mb-0 pTagDynamic">ซ่อนข้อมูล</p>-->
        <!--          <div v-if="showData">-->
        <!--            <v-text-field-->
        <!--              v-model="contactPerson"-->
        <!--              outlined-->
        <!--              dense-->
        <!--              hide-details-->
        <!--              label="ผู้ติดต่อ"-->
        <!--              placeholder="กรอกผู้ติดต่อ"-->
        <!--              class="mt-2 mb-3"-->
        <!--            ></v-text-field>-->
        <!--            <v-text-field-->
        <!--              v-model="emailPerson"-->
        <!--              outlined-->
        <!--              dense-->
        <!--              hide-details-->
        <!--              label="E-mail"-->
        <!--              placeholder="กรอก Email"-->
        <!--              class="mt-2 mb-3"-->
        <!--            ></v-text-field>-->
        <!--            <v-text-field-->
        <!--              v-model="telPerson"-->
        <!--              outlined-->
        <!--              dense-->
        <!--              hide-details-->
        <!--              label="โทรศัพท์"-->
        <!--              placeholder="กรอกเบอร์โทรศัพท์"-->
        <!--              class="mt-2 mb-3"-->
        <!--            ></v-text-field>-->
        <!--          </div>-->
        <!--        </v-col>-->
      </v-row>
      <!-- จบซัพพลายเออร์ -->

      <hr class="mb-3 mt-8"/>

      <!-- เงื่อนไข -->
      <!--      <h3>เงื่อนไขราคา</h3>-->
      <!--      <v-row class="mt-2">-->
      <!--        &lt;!&ndash; สกุลเงิน &ndash;&gt;-->
      <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
      <!--          <h4>สกุลเงิน</h4>-->
      <!--        </v-col>-->
      <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
      <!--          <v-select :items="currency" label="เลือกสกุลเงิน" outlined hide-details></v-select>-->
      <!--        </v-col>-->
      <!--        &lt;!&ndash; เครดิต &ndash;&gt;-->
      <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
      <!--          <h4>เครดิต</h4>-->
      <!--        </v-col>-->
      <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
      <!--          <v-select :items="credit" label="เลือกเครดิต" outlined hide-details></v-select>-->
      <!--        </v-col>-->
      <!--        &lt;!&ndash; ภาษีมูลค่าเพิ่ม &ndash;&gt;-->
      <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
      <!--          <h4>ภาษีมูลค่าเพิ่ม</h4>-->
      <!--        </v-col>-->
      <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
      <!--          <v-select :items="tax" label="เลือกภาษี" outlined hide-details></v-select>-->
      <!--        </v-col>-->
      <!--        &lt;!&ndash; ส่วนลด &ndash;&gt;-->
      <!--        <v-col md="4" sm="6" cols="12" class="d-flex align-center justify-end pt-0 pb-3">-->
      <!--          <h4>ส่วนลด</h4>-->
      <!--        </v-col>-->
      <!--        <v-col md="8" sm="6" cols="12" class="pt-0 pb-3">-->
      <!--          <v-select :items="discount" label="เลือกส่วนลด" outlined hide-details></v-select>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <!-- จบเงื่อนไข -->

      <hr class="mb-3 mt-3"/>

      <!-- สินค้า -->
      <div class="d-flex align-center justify-space-between">
        <div>
          <h3>รายการ</h3>
          <p class="mb-0">รายการใบสั่งซื้อสินค้า Purchase Order</p>
        </div>
        <!--                <v-dialog transition="dialog-top-transition" width="500">-->
        <!--                  <template v-slot:activator="{ on, attrs }">-->
        <!--                    <v-btn color="primary" text v-bind="attrs" v-on="on" class="me-2">-->
        <!--                      เพิ่มโปรเจกต์-->
        <!--                    </v-btn>-->
        <!--                  </template>-->
        <!--                  <template v-slot:default="dialog">-->
        <!--                    <v-card>-->
        <!--                      <v-toolbar color="primary">-->
        <!--                        <p class="mb-0">เพิ่มโปรเจกต์</p>-->
        <!--                      </v-toolbar>-->
        <!--                      <v-card-text class="mt-5">-->
        <!--                        <p class="mb-2">กรอกชื่อโปรเจกต์</p>-->
        <!--                        <v-text-field-->
        <!--                          v-model="projects"-->
        <!--                          outlined-->
        <!--                          dense-->
        <!--                          hide-details-->
        <!--                          label="ชื่อโปรเจกต์"-->
        <!--                          placeholder="กรอกชื่อโปรเจกต์"-->
        <!--                          class="mt-2 mb-3"-->
        <!--                        ></v-text-field>-->
        <!--                      </v-card-text>-->
        <!--                      <v-card-actions class="justify-end">-->
        <!--                        <v-btn color="error" text @click="dialog.value = false">ยกเลิก</v-btn>-->
        <!--                        <v-btn color="primary" @click="dialog.value = false">สร้าง</v-btn>-->
        <!--                      </v-card-actions>-->
        <!--                    </v-card>-->
        <!--                  </template>-->
        <!--                </v-dialog>-->
      </div>
      <v-dialog v-model="dialogAddProduct" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="closeAddProduct"
            style="float: right" color="primary" dark class="mb-3" v-bind="attrs" v-on="on">
            เพิ่มรายการสินค้าสินค้า
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    @change="getPurchaseProduct(amount_product,type)"
                    v-model="amount_product"
                    :items="[1,5,10,20,50,100,500,1000]"
                    label="สินค้าน้อยกว่า">

                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-radio-group
                    v-model="type"
                    @change="getPurchaseProduct(amount_product,type)"
                    label="ประเภทสินค้า">
                    <v-radio value="1" label="ออนไลน์"></v-radio>
                    <v-radio value="2" label="ภายใน"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-select
                    v-model="product_id"
                    label="ค้นหาสินค้า"
                    item-value="id"
                    item-text="product_name"
                    :items="purchase_products">
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeAddProduct">
              Cancel
            </v-btn>
            <v-btn color="primary" text

                   @click="selectProduct">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <table class="table table-hover" style="width: 100%">
        <thead>
        <tr>
          <!--          <th scope="col" style="text-align: left;width: 5%">-->
          <!--            <br>-->
          <!--            &lt;!&ndash;              <input v-model="selectAll" type="checkbox"&ndash;&gt;-->
          <!--            &lt;!&ndash;                         title="เลือกทั้งหมด"&ndash;&gt;-->
          <!--            &lt;!&ndash;                         v-on:click="selectAllFunction">&ndash;&gt;-->
          <!--          </th>-->
          <th scope="col" style="text-align: center;">#</th>
          <th scope="col" style="text-align: center;">ประเภท</th>
          <th scope="col" style="text-align: center">
            รายการ
          </th>
          <th scope="col" style="text-align: left;width: 10%">ราคาหน่วย</th>
          <th scope="col" style="text-align: left;width: 10%">จำนวน</th>
          <th scope="col" style="text-align: left;width: 10%">ส่วนลด</th>
          <th scope="col" style="text-align: right;width: 10%">จำนวนเงิน</th>
          <th scope="col" style="text-align: center;width: 10%">เครื่องมือ
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(list,index) in details">
          <!--          <td><input v-if="list.product_id" v-model="selected" title="เลือก"-->
          <!--                     :value="list.product_id" type="checkbox">-->
          <!--          </td>-->
          <td style="text-align: center">{{ index + 1 }}</td>
          <td style="text-align: center">
            <span v-if="list.product_id">
            <v-checkbox
              dense
              hide-details
              v-model="list.type_online"
              label="ออนไลน์"></v-checkbox>
              <!--            change="typeProduct()-->
            <v-checkbox v-model="list.type_in"
                        dense
                        hide-details
                        label="ภายใน"></v-checkbox>
              </span>

            <span v-if="!list.product_id">
            <v-checkbox
              dense
              hide-details
              v-model="list.type_online"
              label="ออนไลน์"></v-checkbox>
              <!--            change="typeProduct()-->
            <v-checkbox v-model="list.type_in"
                        dense
                        hide-details
                        value="2"
                        label="ภายใน"></v-checkbox>
            </span>

            <!--            <label v-if="list.product_id" for="checkbox"-->
            <!--                   style="font-size: 11px"-->
            <!--                   class="form-check-label"-->
            <!--                   v-on:change="typeProduct()"><input-->
            <!--              type="checkbox" name="type_online" id="type"-->
            <!--              class="form-check-input" v-model="list.type_online"-->
            <!--            >  </label>-->
            <!--            <label v-if="list.product_id" for="checkbox"-->
            <!--                   style="font-size: 11px"-->
            <!--                   class="form-check-label"-->
            <!--                   v-on:change="typeProduct()">-->
            <!--              <v-checkbox label="ออนไลน์"></v-checkbox>-->
            <!--              <input-->
            <!--                value="2"-->
            <!--              type="checkbox" name="type_in"-->
            <!--              class="form-check-input" v-model="list.type_in"-->
            <!--            >-->
            <!--              ภายใน </label>-->
          </td>
          <td>
            <span v-if="list.product_id">
              {{ list.product && list.product.product_name ? list.product.product_name : '-' }}
            </span>
            <span v-if="!list.product_id"></span>

          </td>
          <td>
            <v-text-field
              v-if="list.product_id"
              v-model="list.product ?  list.product.product_costs : list.product_costs"
              placeholder="กรุณากรอกราคา"
              inline
              type="number"></v-text-field>

            <v-text-field
              v-if="!list.product_id"
              v-model="list.product ? list.product.product_costs : list.product_costs"
              placeholder="กรุณากรอกราคา"
              inline
              type="number"></v-text-field>

            <!--            <span v-if="!list.product_costs"-->
            <!--                  v-bind:style="list.product_costs ? '' : 'color: red;'">*กรุณากรอกราคา</span>-->
          </td>
          <td>
            <v-text-field
              type="number"
              v-model="list.amount"
              v-if="list.product_id"
              placeholder="จำนวน"
            ></v-text-field>
          </td>

          <td>
            <v-text-field
              type="number"
              v-model="list.discount"
              v-if="list.product_id"
              :max="list.product_costs*list.amount"
              placeholder="ส่วนลด"
            ></v-text-field>
          </td>
          <td>
            <b><span
              v-if="list.product_id">
              {{
                parseFloat(((list.product ? list.product.product_costs : list.product_costs) * list.amount) - list.discount).toFixed(2)
              }}
              <!--              {{parseFloat(( (list.product ? list.product.product_costs : list.product_costs) * (list.product ? list.product.amount : list.amount) - list.discount)).toFixed(2) }}-->
            </span></b>
          </td>
          <td>
            <!--                        <i v-on:click="Delete(index)"-->
            <!--                           class="fas fa-trash text-danger" title="ลบแถว"></i>-->
            <v-btn
              @click="deleteItem(index)"
            >
              ลบ
            </v-btn>

            <!--            <button v-if="!list.product_id" type="button"-->
            <!--                    :disabled="list.disabled"-->
            <!--                    class="btn btn-warning" v-on:click="addProduct(index)"-->
            <!--                    title="เพิ่มสินค้า">-->
            <!--              เพิ่มสินค้า <i class="fas fa-plus-circle"></i>-->
            <!--            </button>-->
          </td>
        </tr>
        <tr v-if="details.length > 0">
          <th colspan="8" style="text-align: right;">

            <v-checkbox
              dense
              style="float: right"
              hide-details
              value="Y"
              label="คิดภาษีมูลค่าเพิ่ม (VAT 7%)"
              v-model="editedItem.is_vat"></v-checkbox>

          </th>
        </tr>
        <small
          class="validate-err"
          v-for="(err,index) in v$.editedItem.details.$silentErrors"
          v-if="v$.editedItem.details.$error">{{ index > 0 ? ', ' : '' }}
          {{ err.$message }}</small>
        <tr v-if=" details && details.length > 0">
          <th style="text-align: right" colspan="4"></th>
          <th style="text-align: right" colspan="3">รวมเป็นเงินทั้งสิ้น</th>
          <th style="text-align: right">
            {{ parseFloat(subTotal()).toFixed(2) }}
          </th>
        </tr>
        <tr v-if="details.length > 0">
          <th style="text-align: right" colspan="4"></th>
          <th style="text-align: right" colspan="3">หลักส่วนลดพิเศษ</th>
          <th style="text-align: right">
            <v-text-field
              type="number"
              v-model="editedItem.discount"
              :max="parseFloat(subTotal()).toFixed(2)"
              placeholder="ส่วนลด" size="small" inline
            ></v-text-field>
          </th>
        </tr>
        <tr v-if="details.length > 0">
          <th style="text-align: right" colspan="4"></th>
          <th style="text-align: right" colspan="3">ยอดรวมหักหลังส่วนลด</th>
          <th style="text-align: right">
            {{
              parseFloat(subTotal()).toFixed(2) === parseFloat(editedItem.discount).toFixed(2) ? 0.00 : afterDiscount()
            }}
          </th>
        </tr>
        <tr v-if="details.length > 0">
          <th style="text-align: right" colspan="4"></th>
          <th style="text-align: right" colspan="3">จำนวนภาษีมูลค่าเพิ่ม 7 %</th>
          <th style="text-align: right">
            {{
              editedItem.is_vat === 'Y' ? parseFloat(afterDiscount() - VatTotal()).toFixed(2) : 0.00
            }}
          </th>
        </tr>
        <tr v-if="details.length > 0"
            style="background-color: black;color: white;font-weight: 700;font-size: 24px">
          <th style="text-align: right" colspan="3"></th>
          <th style="text-align: right;;color: white" colspan="4">
            <h3
              style="color: white">
              จำนวนเงินรวมทั้งสิ้น
            </h3>
          </th>
          <th style="text-align: right;color: white">
            <h3 style="color: white">
              <div v-if="editedItem.is_vat === 'Y'">
                {{
                  parseFloat(subTotal()).toFixed(2) !== parseFloat(editedItem.discount).toFixed(2) ? parseFloat((subTotal() - editedItem.discount) + (afterDiscount() - VatTotal())).toFixed(2) : ''
                }}
              </div>
              <div v-if="editedItem.is_vat !== 'Y'">
                {{
                  parseFloat(subTotal()).toFixed(2) === parseFloat(editedItem.discount).toFixed(2) ? 0.00 : parseFloat((subTotal() - editedItem.discount)).toFixed(2)
                }}
              </div>
            </h3>
          </th>
        </tr>
        </tbody>
      </table>
      <!--      <v-data-table :headers="headers" :items="editedItem.details" sort-by="calories" class="elevation-1 mt-4">-->
      <!--        <template v-slot:top>-->
      <!--          <v-toolbar flat>-->
      <!--            <v-divider class="mx-4" inset vertical></v-divider>-->
      <!--            <v-spacer></v-spacer>-->
      <!--            <v-dialog v-model="dialog" max-width="500px">-->
      <!--              <template v-slot:activator="{ on, attrs }">-->
      <!--                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">-->
      <!--                  เพิ่มรายการสินค้าสินค้า-->
      <!--                </v-btn>-->
      <!--              </template>-->
      <!--              <v-card>-->
      <!--                <v-card-title>-->
      <!--                  <span class="text-h5">{{ formTitle }}</span>-->
      <!--                </v-card-title>-->

      <!--                <v-card-text>-->
      <!--                  <v-container>-->
      <!--                    <v-row>-->
      <!--                      <v-col cols="12" sm="12" md="12">-->
      <!--                        <v-select-->
      <!--                          @change="getPurchaseProduct(amount_product,type)"-->
      <!--                          v-model="amount_product"-->
      <!--                          :items="[1,5,10,20,50,100,500,1000]"-->
      <!--                          label="สินค้าน้อยกว่า">-->

      <!--                        </v-select>-->
      <!--                      </v-col>-->
      <!--                      <v-col cols="12" sm="12" md="12">-->
      <!--                        <v-radio-group-->
      <!--                          v-model="type"-->
      <!--                          @change="getPurchaseProduct(amount_product,type)"-->
      <!--                          label="ประเภทสินค้า">-->
      <!--                          <v-radio value="1" label="ออนไลน์"></v-radio>-->
      <!--                          <v-radio value="2" label="ภายใน"></v-radio>-->
      <!--                        </v-radio-group>-->
      <!--                      </v-col>-->

      <!--                      <v-col cols="12" sm="12" md="12">-->
      <!--                        <v-select-->
      <!--                          v-model="product_id"-->
      <!--                          label="ค้นหาสินค้า"-->
      <!--                          item-value="id"-->
      <!--                          item-text="product_name"-->
      <!--                          :items="purchase_products">-->
      <!--                        </v-select>-->
      <!--                      </v-col>-->
      <!--                    </v-row>-->
      <!--                  </v-container>-->
      <!--                </v-card-text>-->

      <!--                <v-card-actions>-->
      <!--                  <v-spacer></v-spacer>-->
      <!--                  <v-btn color="error" text @click="close">-->
      <!--                    Cancel-->
      <!--                  </v-btn>-->
      <!--                  <v-btn color="primary" text @click="selectProduct">-->
      <!--                    Save-->
      <!--                  </v-btn>-->
      <!--                </v-card-actions>-->
      <!--              </v-card>-->
      <!--            </v-dialog>-->
      <!--            <v-dialog v-model="dialogDelete" max-width="500px">-->
      <!--              <v-card>-->
      <!--                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>-->
      <!--                <v-card-actions>-->
      <!--                  <v-spacer></v-spacer>-->
      <!--                  <v-btn color="error" text @click="closeDelete">Cancel</v-btn>-->
      <!--                  <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>-->
      <!--                  <v-spacer></v-spacer>-->
      <!--                </v-card-actions>-->
      <!--              </v-card>-->
      <!--            </v-dialog>-->
      <!--          </v-toolbar>-->
      <!--        </template>-->
      <!--        <template v-slot:item.actions="{ item }">-->
      <!--          <v-icon small class="mr-2" @click="editItem(item)">-->
      <!--            {{ icons.mdiPencil }}-->
      <!--          </v-icon>-->
      <!--          <v-icon small @click="deleteItem(item)">-->
      <!--            {{ icons.mdiDelete }}-->
      <!--          </v-icon>-->
      <!--        </template>-->
      <!--        <template v-slot:item.index="{ index }">-->
      <!--         {{index +1 }}-->
      <!--        </template>-->
      <!--        <template v-slot:no-data>-->
      <!--          <v-btn color="primary" @click="initialize">-->
      <!--            Reset-->
      <!--          </v-btn>-->
      <!--        </template>-->
      <!--      </v-data-table>-->
      <!--      <p class="text-end mb-0 mt-3 font-weight-bold">-->
      <!--        ยอดรวม <span class="font-weight-regular ms-5">{{ editedItem.sub_total }}</span>-->
      <!--      </p>-->
      <!--      <p class="text-end mb-0 font-weight-bold">-->
      <!--        ยอดเงินสุทธิ <span class="font-weight-regular ms-5">{{ editedItem.total }}</span>-->
      <!--      </p>-->
      <!--      <v-row>-->
      <!--        <v-col cols="12" sm="6" md="4">-->
      <!--          <p class="mb-0">หมายเหตุเอกสาร</p>-->
      <!--        </v-col>-->
      <!--        <v-col cols="12" sm="6" md="8">-->
      <!--          <p @click="showTextarea" class="mb-0 pTagDynamic">เพิ่มหมายเหตุ</p>-->
      <!--          <v-textarea v-if="textAreaContent" outlined hide-details label="เพิ่มหมายเหตุ"-->
      <!--                      :value="note"></v-textarea>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <!-- จบสินค้า -->

      <hr class="mt-3 mb-3"/>

      <!-- เอกสาร และโน้ต-->
      <h3>เอกสารแนบ และโน้ต</h3>
      <p class="mb-0">เพิ่มเอกสาร หรือข้อความแนบสำหรับใบสั่งซื้อนี้</p>
      <v-row class="mt-2">
        <v-col cols="12" sm="6" md="4">
          <h4 class="mb-0 text-end">โน้ต</h4>
        </v-col>
        <v-col cols="12" sm="6" md="8">
          <v-textarea
            v-model="editedItem.note"
            outlined hide-details label="โน้ตอื่น ๆ เพื่อใช้ในบริษัท"
            :value="noteFile"></v-textarea>
          <!--          round-->
          <!--          <v-btn color="primary" class="text-none mt-3" depressed :loading="isSelecting"-->
          <!--                 @click="onButtonClick">-->
          <!--            <v-icon left>-->
          <!--              {{ icons.mdiCloudUpload }}-->
          <!--            </v-icon>-->
          <!--            {{ buttonText }}-->
          <!--          </v-btn>-->
          <input ref="uploader" class="d-none" type="file" @change="onFileChanged"/>
        </v-col>
      </v-row>
      <!-- จบเอกสาร และโน๊ต -->
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn color="error" text @click="close()">ยกเลิก</v-btn>
      <v-btn
        v-if="editedItem.status != 'Y' && editedItem.details && editedItem.details.length > 0"
        title="บันทึกร่าง"
        color="secondary" @click="storePurchase('draft')">บันทึกฉบับร่าง
      </v-btn>

      <!--      <v-btn-->
      <!--        title="สินค้าค้างรับ"-->
      <!--        v-if="purchase_order_type === 2 && editedItem.status != 'Y' && editedItem.details.length > 0"-->
      <!--        color="primary" @click="storePurchase('Not complete')">สินค้าค้างรับ</v-btn>-->

      <v-btn color="primary"
             v-if="editedItem.status != 'Y' && editedItem.details && editedItem.details.length > 0"
             @click="storePurchase('save')">บันทึก
      </v-btn>

    </v-card-actions>

  </v-card>

</template>

<script>
import {mdiMagnify, mdiPencil, mdiDelete, mdiCloudUpload} from '@mdi/js'
import RevenueDepartment from './RevenueDepartment.vue'
import instance_payment from '../../../services/payment'
import instance_purchase_order from '../../../services/purchaseOrder'
import instance_pickup from "@/services/purchaseOrder";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";

export default {
  props: ['data', 'payments', 'close', 'getPurchaseOrder', 'purchase_products', 'getPurchaseProduct', 'save', 'v$'],
  components: {DatePicker, RevenueDepartment, Swal},
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiCloudUpload,
      },
    }
  },
  data() {
    return {
      details: [],
      amount_product: '',
      type: '1',
      // payments : [],
      menu: false,
      modal: false,
      menu2: false,
      radioGroup: 1,
      radioGroup2: 1,
      nameSupplier: '',
      product_id: '',
      address: '',
      office: '',
      contactPerson: '',
      emailPerson: '',
      projects: '',
      telPerson: null,
      numberTax: null,
      dataRadio: ['สินค้า', 'จ้างบริการ/ค่าใช้จ่าย', 'สินทรัพย์'],
      buyForm: ['บริษัท / ห้างร้าน', 'บุคคลทั่วไป'],
      showData: false,
      currency: ['THB', 'เพิ่มเติม'],
      credit: ['ไม่มี', '7 วัน', '15 วัน', '30 วัน', '45 วัน', '60 วัน', '90 วัน', 'ระบุเอง'],
      tax: ['ไม่มีภาษี', 'แยกภาษี 7%', 'รวมภาษี 7%', 'ยื่น ภพ.36'],
      discount: ['ไม่มีส่วนลด', 'ส่วนลดรวม', 'ส่วนลดแยกตามสินค้า'],
      dialog: false,
      dialogDelete: false,
      dialogAddProduct: false,
      total: 120,
      netAmount: 120,
      textAreaContent: false,
      note: '',
      noteFile: '',
      defaultButtonText: 'ไฟล์แนบ',
      selectedFile: null,
      isSelecting: false,
      editedItem: '',
      headers: [
        // {
        //   text: 'สินค้า',
        //   align: 'start',
        //   sortable: false,
        //   value: 'nameProduct',
        // },
        {text: '#', value: 'index'},
        {text: 'ประเภท', value: 'type'},
        {text: 'รายการ', value: 'product_name'},
        {text: 'ราคาหน่วย', value: 'price'},
        // <number-input v-if="list.product_id" placeholder="กรุณากรอกราคา"
        //               inline
        //               v-model="list.product_costs"></number-input>
        // <div v-if="!list.product_id">
        //   {{-- add product--}}
        //   <input min="1" type="number" v-if="!list.product_id"
        //          className="form-control" placeholder="กรุณากรอกราคา"
        //          v-model="list.product_costs"
        //          v-bind:style="list.product_costs ? '' : 'color: red;'">
        //                                                     <span v-if="!list.product_costs"
        //                                                           v-bind:style="list.product_costs ? '' : 'color: red;'">*กรุณากรอกราคา</span>
        //
        // </div>
        {text: 'จำนวน', value: 'amount'},

        // <number-input v-if="list.product_id" placeholder="จำนวน"
        //               size="small" inline controls
        //
        //               v-model="list.amount"></number-input>
        {text: 'ส่วนลด', value: 'discount'},
        // <number-input v-if="list.product_id" placeholder="ส่วนสด" inline
        //               v-model="list.discount"
        //                                                               :max="list.product_costs*list.amount"></number-input>
        {text: 'จำนวนเงิน', value: 'total'},
        // <b><span v-if="list.product_id"> @{{parseFloat((list.product_costs*list.amount)-list.discount).toFixed(2)}}</span></b>
        {text: 'แก้ไข', value: 'actions', sortable: false},
      ],
      desserts: [],
      editedIndex: -1,
      // editedItem: {
      //   purchase_on: '',
      //   updated_at: '',
      //   date: '',
      //   supplier_name: '',
      //   contact_name: '',
      //   address: '',
      //   payment_id: '',
      //   project_name: '',
      //   employee: '',
      //   employee_id: '',
      //   note: '',
      //   tax_id: '',
      //   discount: '',
      //   payment_type: '',
      //   status: '',
      //   vat: '',
      //   sub_total: '',
      //   total: '',
      // },
      // defaultItem: {
      //   purchase_on: '',
      //   updated_at: '',
      //   date: '',
      //   supplier_name: '',
      //   contact_name: '',
      //   address: '',
      //   payment_id: '',
      //   project_name: '',
      //   employee: '',
      //   employee_id: '',
      //   note: '',
      //   tax_id: '',
      //   discount: '',
      //   payment_type: '',
      //   status: '',
      //   vat: '',
      //   sub_total: '',
      //   total: '',
      // },
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่มรายการสินค้า' : 'Edit Item'
    },
    buttonText() {
      return this.selectedFile ? this.selectedFile.name : this.defaultButtonText
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },
  methods: {
    subTotal: function () {
      let total = [];
      Object.entries(this.details).forEach(([key, val]) => {
        let sum = 0;
        if (val.product_id) {
          let product_costs = val.product ? val.product.product_costs : val.product_costs;
          let amount = val.amount;
          let discount = parseFloat(val.discount);
          if ((product_costs * amount) > discount) {
            sum = (product_costs * amount) - discount
          } else {
            sum = (product_costs * amount)
          }
        }

        total.push(parseFloat(sum).toFixed(2));
      });

      return total.reduce(function (total, num) {
        return parseFloat(total) + parseFloat(num)
      }, 0);

    },
    afterDiscount: function () {
      let subTotal = this.subTotal();
      let discount = this.editedItem.discount;
      return parseFloat(subTotal - discount).toFixed(2);
    }
    ,
    VatTotal: function () {
      let afterDiscount = this.afterDiscount();
      let vat = parseFloat((afterDiscount * 7) / 100).toFixed(2);
      return parseFloat(afterDiscount - vat).toFixed(2);
    },
    storePurchase(status) {

      this.editedItem.details = this.details
      let total = 0;
      if (this.editedItem.is_vat === 'Y') {
        total = parseFloat((this.subTotal() - this.editedItem.discount) + (this.afterDiscount() - this.VatTotal())).toFixed(2)

      } else {
        total = parseFloat((this.subTotal() - this.editedItem.discount)).toFixed(2)
      }

      this.$emit("editedItem", this.editedItem)
      this.save(status, this.subTotal(), total);
    },
    closeAddProduct() {
      this.type = '1';
      this.product_id = '';
      this.amount_product = 1
      this.dialogAddProduct = false;
    },
    selectAmountProduct() {
      this.getPurchaseProduct(this.amount_product);
    },
    getPurchaseOrderDetail(id) {
      instance_purchase_order.getPurchaseOrderDetail(id)
        .then(res => {
          this.editedItem = res.data.purchase_orders;
          this.details = res.data.purchase_order_details;
        }).catch(err => {

      })
    },
    clickShowData() {
      this.showData = !this.showData
    },
    initialize() {
      this.desserts = [
        {
          nameProduct: 'Frozen Yogurt',
          quantity: 159,
          priceUnit: 6.0,
          price: 24,
        },
        {
          nameProduct: 'Ice cream sandwich',
          quantity: 237,
          priceUnit: 9.0,
          price: 37,
        },
        {
          nameProduct: 'Eclair',
          quantity: 262,
          priceUnit: 16.0,
          price: 23,
        },
      ]
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(index) {
      this.details.splice(index, 1);
      // this.editedIndex = this.desserts.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      // this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    // close() {
    //   this.dialog = false
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem)
    //     this.editedIndex = -1
    //   })
    // },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    showTextarea() {
      this.textAreaContent = !this.textAreaContent
    },

    addProductCheckValidate() {
      Object.entries(this.editedItem.details).forEach(([key, val]) => {
        if (!val.product_id && (!val.product_name || !val.product_costs)) {
          this.count += 1;
        }
      });
      if (this.count > 1) {
        this.editedItem.details.splice(-1);

        Swal.fire({
          icon: 'error',
          title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
          showConfirmButton: false,
          timer: 1500
        })
      }
    },
    selectProduct(item) {

      let check = true;
      Object.entries(this.purchase_products).forEach(([key, val]) => {
        if (val.id == this.product_id) {
          this.product_id = val;
          return false;
        }
      })

      Object.entries(this.editedItem.details).forEach(([key, val]) => {
        if (val.product_code === this.product_id.product_code) {
          if (this.product_id.type_product === 1) {
            this.editedItem.details[key].product_amount_online = this.editedItem.details.product_amount;
            ;
          }
          if (this.product_id.type_product === 2) {
            this.editedItem.details[key].product_amount = this.product_id.product_amount;
          }
          check = false
          Swal.fire({
            icon: 'error',
            title: 'สินค้ามีอยู่ในรายการเเล้ว',
            showConfirmButton: false,
            timer: 1500
          })
        }
      });

      if (check) {
        let product_select = this.product_id;
        if (product_select.type_product === 1) {
          product_select.product_amount_online = this.product_id.product_amount_online;
        }
        if (product_select.type_product === 2) {
          product_select.product_amount = this.product_id.product_amount;
        }
        product_select.type_in = true;
        product_select.type_online = true;
        this.editedItem.details.push(product_select)

        // this.checkParentProduct();
        this.addProductCheckValidate();
      }


      // var tmp = {
      //   id : '',
      //   price : 0,
      //   product : this.product_id,
      //   product_id : this.product_id.product_id,
      //   type : 'All',
      //   purchase_order_id : '',
      //   amount : 0,
      //   discount : 0,
      // }
      // this.editedItem.details.push(tmp)
      this.dialogAddProduct = false;

    },
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        {once: true},
      )

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]

      // do something
    },
  },
  mounted() {
    this.close();
    if (this.data.id) {
      this.getPurchaseOrderDetail(this.data.id);
    }
  }
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}

.pTagDynamic {
  color: #8dbc8a;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.pTagDynamic:hover {
  color: #728f70;
}
</style>
